import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/antonio-escritorio/Documents/moare/sites-estaticos/libres-de-violencias/src/components/default-mdx-layout.js";
import SpeakerSingle from "../../src/components/speaker-single";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SpeakerSingle name="Pastora Filigrana" profession="Abogada y defensora de Derechos Humanos (Sevilla)" presentation="Las violencias institucionales que sufren las trabajadoras migrantes." youtubeid="lFTcXDJzM-Y" hasarticle="true" skipTo={{
      h: 0,
      m: 31,
      s: 36
    }} mdxType="SpeakerSingle" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      